/* You can add global styles to this file, and also import other style files */

// @import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap');

/* vietnamese */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 400;
  // src: url(https://fonts.gstatic.com/s/questrial/v18/QdVUSTchPBm7nuUeVf70sSFluW44JUcz.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 400;
  // src: url(https://fonts.gstatic.com/s/questrial/v18/QdVUSTchPBm7nuUeVf70sCFluW44JUcz.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 400;
  // src: url(https://fonts.gstatic.com/s/questrial/v18/QdVUSTchPBm7nuUeVf70viFluW44JQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Josefin Sans", sans-serif;
}

html,
body {
  height: 100%;
  overscroll-behavior-y: contain !important;
  touch-action: none !important;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

}

.splash-screen {
  min-height: 100%;
  display: grid;
  align-items: center;
  justify-items: center;
}

.splash-screen:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #032311;
}

.splash-screen .splash-screen-bars {
  width: 135px;
  height: 140px;
  position: absolute;
  background: url("../src/assets/images/bars.svg");
}

html {
  scroll-behavior: smooth;

}

@media only screen and (max-width: 1080px) and (min-width: 1080px) {
  body {
    cursor: none !important;
  }
}

:root {
  // colors for themeing
  --primary: #000;
  --secondary: #e7a200;
  --background: #3c2015;
  --accent: #c89d28;
  --selectedBtn: #B98200;
  --scrollbarcolor: #B98200;
  --scrollbarcolor2: #B98200;
  --categoryList: #746240;
  --highlightedColor: #ECB533;
  --categoryButton: #AD7A00;
  --categoryText: #C48A00;
  --headerText: #F5DEB3;
  --mainText: #fff;
  --accordianTxt: #F5DEB3;
  --unselectedColor: #3C2015;
  --unselectedText: #fff;
  --selectedText: #F5DEB3;
  --badgeColorOpaque: #684400BF;
  --backdropDarkColor: #150500;
  --backdropLightColor: #4d2824;
  --modalBackgroundColor: #3c2015;
  --secondaryLight: #EDB940;
  --boxshadowShade: #EAAE21;
  --btnQuantityTxt: #fff;
  --wallpaperTxt: #fff;
  --popupColor: #1F1F29;
  --popupBackground: #FFF0E9;
}

*::-webkit-scrollbar {
  scrollbar-width: thin;
  display: none;
}

*::-webkit-scrollbar-thumb {
  display: block;
  background-color: var(--scrollbarcolor);
  border-radius: 5000px;
}

*::-webkit-scrollbar-track {
  display: block;
  background-color: transparent;
  border-radius: 5000px;
}


iframe {
  transform: scale(1.8) !important;
  -webkit-transform: scale(1.8) !important;
  -moz-transform: scale(1.8) !important;
}

.scroll-container-left {
  position: fixed;
  bottom: 8vh;
  left: 6vw;
  transition: 0.5s ease-in;
  z-index: 5;

  &.drawer-open {
    bottom: 20vh;
  }

  &.top {
    bottom: 81vh;
    transform: rotate(180deg);
  }
}

.scroll-container-right {
  position: fixed;
  bottom: 8vh;
  right: 1.5vw;
  transition: 0.5s ease-in;
  z-index: 5;

  &.drawer-open {
    bottom: 20vh;
  }

  &.top {
    bottom: 81vh;
    transform: rotate(180deg);
  }
}

// media queries for adjusting razorpay screen
@media (max-width: 480px) {
  iframe {
    transform: scale(0.8) !important;
    -webkit-transform: scale(0.8) !important;
    -moz-transform: scale(0.8) !important;
  }

}

@media (min-width: 900px) and (max-height: 1380px) {
  iframe {
    transform: scale(1) !important;
    -webkit-transform: scale(1) !important;
    -moz-transform: scale(1) !important;
  }
}

@media (min-width: 768px) and (max-width: 900px) {

  .scroll-container-left,
  .scroll-container-right {
    &.drawer-open {
      bottom: 28vh;
    }
  }

}

@media (min-width: 450px) and (max-width: 767px) {
}

@media (min-width: 768px) and (max-width: 799px) and (min-height: 1000px) {
}
